export interface ErrorAPIResponse {
  title: string;
  status: number;
  detail: string;
  message?: string;
  id: string;
}

export class APIErrorResponse implements ErrorAPIResponse {
  title: string;
  status: number;
  detail: string;
  message?: string;
  id: string;

  constructor(title: string, status: number, detail: string, id: string, message?: string) {
    this.title = title;
    this.status = status;
    this.detail = detail;
    this.id = id;
    this.message = message;
  }
}
