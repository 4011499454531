import React, { useContext } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Button
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import LayersIcon from '@mui/icons-material/Layers';
import DescriptionIcon from '@mui/icons-material/Description';
import logo from './../sumrisev5-white.svg';
import { UserManagerContext } from '../utils/context/UserManagerContext';
import { useNavigate } from 'react-router-dom';
import { getData, logout, postData } from 'api/ApiRequests';
import HomeIcon from '@mui/icons-material/Home';

const item = {
  px: 1,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)'
  }
};

const NavigationItem = ({ path, children, icon }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(path);
  };

  return (
    <ListItem sx={{ px: 0, color: 'rgba(255, 255, 255, 0.7)' }}>
      <ListItemButton sx={{ color: '#fff' }} onClick={handleNavigate}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={children} />
      </ListItemButton>
    </ListItem>
  );
};

const Navigator = (props) => {
  const userManager: any = useContext(UserManagerContext);

  const handleLogout: any = () => {
    logout(userManager);
  };

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { width: props.drawerWidth }
      }}>
      <Box sx={{ px: 3, py: 3 }}>
        <img src={logo} alt="sumrise-admin" />
      </Box>
      <List>
        <NavigationItem path="/home" icon={<HomeIcon sx={{ color: '#fff' }} />}>
          Home
        </NavigationItem>
        <NavigationItem path="/users?page=1" icon={<GroupIcon sx={{ color: '#fff' }} />}>
          Users
        </NavigationItem>
        <NavigationItem path="/companies?page=1" icon={<BusinessIcon sx={{ color: '#fff' }} />}>
          Companies
        </NavigationItem>
        <NavigationItem path="/plans?page=1" icon={<LayersIcon sx={{ color: '#fff' }} />}>
          Plans
        </NavigationItem>
        <NavigationItem path="/invoices?page=1" icon={<DescriptionIcon sx={{ color: '#fff' }} />}>
          Invoices
        </NavigationItem>
      </List>
      <ListItem sx={{ px: 0, color: 'rgba(255, 255, 255, 0.7)', mt: 'auto' }}>
        <ListItemButton sx={{ color: '#fff' }} onClick={handleLogout}>
          <ListItemText primary={'Log out'} sx={{ textAlign: 'center' }} />
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
};

export default Navigator;
