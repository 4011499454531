import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: process.env.REACT_APP_VISMA_AUTH_AUTHORITY,
  client_id: process.env.REACT_APP_VISMA_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_VISMA_AUTH_REDIRECT_URI,
  response_type: process.env.REACT_APP_VISMA_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_VISMA_AUTH_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_VISMA_AUTH_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTimeInSeconds: 300
});

export default userManager;
