import { Box, CircularProgress } from '@mui/material';
import Header from 'components/Header';
import React from 'react';

const CircularLoader = () => {
  return (
    <>
      <Header title={''} tabs={[]} />
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={80} />
      </Box>
    </>
  );
};

export default CircularLoader;
