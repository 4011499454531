import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserManagerContext } from './utils/context/UserManagerContext';
import Home from 'components/Home';
import userManager from './utils/UserManager';
import { SnackbarProvider } from 'components/SnackbarProvider';
import AppWrapper from 'components/wrapper/AppWrapper';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import Loading from 'components/Loading';

function App() {
  const isProdOrStag = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProdOrStag} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <UserManagerContext.Provider value={userManager}>
        <SnackbarProvider>
          <Router>
            <AppWrapper>
              <Home />
            </AppWrapper>
          </Router>
        </SnackbarProvider>
      </UserManagerContext.Provider>
    </CacheBuster>
  );
}

export default App;
