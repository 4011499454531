import React from 'react';

interface SnackbarContextProps {
  open: boolean;
  message: string;
  severity: 'success' | 'warning' | 'error';
  showSnackbar: (message: string, severity: 'success' | 'warning' | 'error') => void;
  closeSnackbar: () => void;
}

export const SnackbarContext = React.createContext<SnackbarContextProps>({
  open: false,
  message: '',
  severity: 'success',
  showSnackbar: () => {},
  closeSnackbar: () => {}
});
