import React, { useEffect, useState, lazy, Suspense } from 'react';
import Navigator from './Navigator';
import Box from '@mui/material/Box';
import { DRAWER_WIDTH } from 'config/Config';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import userManager from '../utils/UserManager';
import CircularLoader from './loading/CircularLoader';

// Lazy load components
const Callback = lazy(() => import('./Callback').then((module) => ({ default: module.Callback })));
const Users = lazy(() => import('./user/Users'));
const Unauth = lazy(() => import('./error/Unauth'));
const Companies = lazy(() => import('./company/overview/Companies'));
const PlanDetail = lazy(() => import('./plan/PlanDetail'));
const PlansOverview = lazy(() => import('./plan/PlansOverview'));
const CompanyDetail = lazy(() => import('./company/detail/CompanyDetail'));
const HomePage = lazy(() => import('./home/HomePage'));
const UserDetail = lazy(() => import('./user/UserDetail'));
const CompanyDetailJournalDetail = lazy(
  () => import('./company/detail/journal/CompanyDetailJournalDetail')
);
const InvoiceOverview = lazy(() => import('./invoice/overview/InvoiceOverview'));
const InvoiceDetail = lazy(() => import('./invoice/detail/InvoiceDetail'));

function Home() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  function hasRequiredScopes(userScopes = []) {
    const missingScopes = requiredScopes.filter((scope) => !userScopes.includes(scope));

    if (missingScopes.length > 0) {
      console.log(`Missing scopes: ${missingScopes.join(', ')}`);
      return false;
    }

    return true;
  }

  const requiredScopes = process.env.REACT_APP_VISMA_AUTH_SCOPE.split(' ');

  const authenticateUser = () => {
    userManager
      .getUser()
      .then((user) => {
        if (!user || user.expired || !user.scopes) {
          userManager.signinRedirect();
          return;
        }
        if (!hasRequiredScopes(user.scopes) && location.pathname !== '/unauthorized') {
          navigate('/unauthorized');
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.error('Error authenticating: ', e);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (location.pathname !== '/callback') {
      authenticateUser();
    } else {
      setIsLoading(false);
    }
  }, [location]);

  const showNavigator = location.pathname !== '/unauthorized' && !isLoading;

  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={80} />
      </Box>
    );
  }

  return (
    <Suspense fallback={<CircularLoader />}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {showNavigator && (
          <Box sx={{ width: DRAWER_WIDTH }}>
            <Navigator drawerWidth={DRAWER_WIDTH} />
          </Box>
        )}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Routes>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/users" element={<Users />} index />
            <Route path="/companies" element={<Companies />} />
            <Route path="/company/:companyId" element={<CompanyDetail />} />
            <Route
              path="/company/:companyId/journal/:journalId"
              element={<CompanyDetailJournalDetail />}
            />
            <Route path="/company/:companyId/user/:id" element={<UserDetail />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/user/:id" element={<UserDetail />} />
            <Route path="/unauthorized" element={<Unauth />} />
            <Route path="/plans" element={<PlansOverview />} />
            <Route path="/plan/:id" element={<PlanDetail />} />
            <Route path="/invoices" element={<InvoiceOverview />} />
            <Route path="/invoice/:invoiceId" element={<InvoiceDetail />} />
          </Routes>
        </Box>
      </Box>
    </Suspense>
  );
}

export default Home;
