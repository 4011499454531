export class FetchError extends Error {
  body: any;
  status: number;

  constructor(message: string, status: number, body: any) {
    super(message);
    this.status = status;
    this.body = body;
  }
}
