import { createTheme } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      light: '#1c4666',
      main: '#133249',
      dark: '#0a1d2c'
    },
    secondary: {
      main: '#d70048'
    }
  }
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#133249'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightLight
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: theme.palette.common.white
        },
        indicator: {
          backgroundColor: theme.palette.common.white,
          height: 4
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: 'inherit',
          '&.Mui-selected': {
            color: '#fff'
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontSize: '0.83em'
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            fontSize: '0.83rem'
          },
          '& .MuiInputBase-root': {
            fontSize: '1rem'
          },
          '& .MuiGrid-root': {
            margin: '-13px 0px 0px 0px'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiGrid-item': {
            paddingLeft: 0
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            fontSize: '0.84rem'
          },
          '& .MuiDataGrid-cell': {
            fontSize: '0.86rem'
          },
          '& .MuiSelect-select': {
            fontSize: '0.84rem'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.88rem'
        }
      }
    }
  }
};

export default theme;
