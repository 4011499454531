import { Tab, Tabs, Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import React from 'react';

interface HeaderProps {
  tabIndex?: number;
  title: string;
  tabs: string[];
  handleTabChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

const Header: React.FC<HeaderProps> = ({ tabIndex = 0, title, tabs, handleTabChange }) => {
  return (
    <React.Fragment>
      <AppBar sx={{ backgroundColor: '#86bbd8' }} position="sticky" elevation={0}>
        <Toolbar sx={{ flexWrap: 'wrap', paddingTop: 3 }}>
          <h1>{title}</h1>
        </Toolbar>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab} />
          ))}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
